
import Vue from 'vue';
import { AUTH_COOKIE_REFRESH_TOKEN } from '@/views/Auth/Login.vue';

export default Vue.extend({
  name: 'Logout',
  created() {
    this.$cookies.remove(AUTH_COOKIE_REFRESH_TOKEN);
    this.$router.push({ name: 'auth.login' });
  },
});
