
import Vue from 'vue';

export default Vue.extend({
  name: 'Callback',
  created() {
    console.log('Authentication callback received');
    this.$auth.initializeCallbackToken(window.location.href)
      .then(() => {
        console.log('Authentication callback completed, redirecting user to main view');
        this.$router.push({ name: 'portal.calendar.show' });
      });
  },
});
